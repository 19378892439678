<template>
  <div id="theories-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead mb-5">
      {{ activeRoute.description }}
    </p>

    <router-link to="/theories" class="btn btn-primary mb-4">Zurück zur Theorieübersicht</router-link>

    <div class="card">
      <div class="card-header">
        <h3 class="mb-0">{{ theory.title }}</h3>
      </div>
      <div class="card-body">
        <h4>Beschreibung:</h4>
        <p>
          {{ theory.description }}
        </p>

        <hr>

        <h4 class="mb-3">Grundlagen deiner Theorie</h4>
        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="card h-100">
              <div class="card-body">
                <h5>Annahmen:</h5>
                <div v-if="theory.assumptions && theory.assumptions.length" class="row">
                  <div v-for="(assumption, index) in theory.assumptions" class="col-sm-6 col-md-4 mb-3" :key="index">
                    <div class="card h-100">
                      <div class="card-body">
                        {{ assumption }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <p>Noch keine Annahmen erstellt</p>
                  <router-link :to="'/theories/edit/' + theory.id" class="btn btn-outline-primary">Jetzt Annahmen hinzufügen</router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="card h-100">
              <div class="card-body">
                <h5>Beispiele:</h5>
                <div v-if="theory.examples && theory.examples.length" class="row">
                  <div v-for="(example, index) in theory.examples" class="col-sm-6 col-md-4 mb-3" :key="index">
                    <div class="card h-100">
                      <div class="card-body">
                        {{ example }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <p>Noch keine Beispiele erstellt</p>
                  <router-link :to="'/theories/edit/' + theory.id" class="btn btn-outline-primary">Jetzt Beispiele hinzufügen</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr>

        <h4>Deine Validierungen</h4>

        <div class="row">
          <div v-for="(prop, index) in props" class="col-md-6 mb-3" :key="'p' + index">
            <div class="card h-100">
              <div class="card-body">
                <h5>{{ prop.title }}</h5>
                <ul v-if="theory[prop.key] && theory[prop.key].length">
                  <li v-for="(value, index) in theory[prop.key]" :key="'v' + index">{{ value.title }}</li>
                </ul>
                <div v-else>
                  <p>Noch keine {{ prop.title }} erstellt</p>
                  <router-link :to="'/' + prop.key" class="btn btn-outline-primary">Jetzt {{ prop.title }} hinzufügen</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <router-link :to="'/theories/edit/' + theory.id" class="btn btn-primary mr-3">Theorie bearbeiten</router-link>
        <button @click="deleteTheory" class="btn btn-danger">Theorie löschen</button>
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'TheoryDetails',
  components: {
  },
  data() {
    return {
      theory: {},
      props: [
        { key: 'audits', title: 'Audits' },
        { key: 'findings', title: 'Findings' },
        { key: 'ideas', title: 'Ideen' },
        { key: 'experiments', title: 'Experimente' },
      ]
    }
  },
  computed: {
    audits() {
      return this.project.audits.filter(audit => audit.theoryId === this.theory.id);
    },
    findings() {
      return this.project.findings.filter(finding => finding.theoryId === this.theory.id);
    },
    ideas() {
      return this.project.ideas.filter(idea => idea.theoryId === this.theory.id);
    },
    experiments() {
      return this.project.experiments.filter(experiment => experiment.theoryIds.find(this.theory.id));
    },
  },
  methods: {
    addTheory(theory) {
      let temp = JSON.parse(JSON.stringify(theory));
      temp.id = this.generateId();

      this.project.theories.push(temp);
      this.$store.dispatch('project/updateProjectByProp', {prop: 'theories', data: this.project.theories})
          .then(() => {
            this.$forceUpdate();
          });
    },
    deleteTheory() {
      let theories = this.project.theories;
      let index = theories.findIndex(theory => theory.id === this.theory.id);
      theories.splice(index, 1);

      let members = JSON.parse(JSON.stringify(this.project.members));
      members = members.map(member => {
        if (member.activeTheoryId === this.theory.id) {
          member.activeTheoryId = "";
        }
        return member;
      });

      if (members.length === 0) {
        console.warn("Error switching Theory");
      } else {
        this.$store.dispatch('project/updateProjectByProp', {prop: 'members', data: members})
            .then(() => {
              this.$store.dispatch('project/updateProjectByProp', {prop: 'theories', data: theories})
                  .then(() => {
                    this.$router.push("/theories");
                  });
            })
            .catch(() => {
            });
      }
    }
  },
  beforeMount() {
    this.theory = this.project.theories.find(theory => theory.id === this.$route.params.id);

    let members = JSON.parse(JSON.stringify(this.project.members));
    members.find(member => member.email === this.user.email).settings.activeTheoryId = this.theory.id;

    if (members.length === 0) {
      console.warn("Error switching Theory");
    } else {
      this.$store.dispatch('project/updateProjectByProp', {prop: 'members', data: members })
          .catch(err => {
            console.error(err);
          });
    }
  }
}
</script>
